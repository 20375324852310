import { makeStyles } from 'tss-react/mui';
import { colors, spacing } from '../../styles/jss/variables';
const useStyles = makeStyles()((theme, _params, classes) => ({
    heading: Object.assign(Object.assign(Object.assign({ display: 'flex', flexDirection: 'column' }, theme.typography.xl_regular), theme.typographySpacing.none), { color: colors.supplementary.white, marginBottom: spacing.small, [theme.breakpoints.up('md')]: Object.assign(Object.assign({}, theme.typography.xxl), { marginBottom: spacing.medium }), [theme.breakpoints.up('lg')]: Object.assign(Object.assign({}, theme.typography.xxxxl), { marginBottom: spacing.large }) }),
    subheading: {
        [theme.breakpoints.down('xl')]: {
            marginBottom: spacing.medium,
        },
    },
    message: {},
    linkContainer: {
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexDirection: 'row',
            margin: `0 -${spacing.xs}`,
            [`& .${classes.link}`]: {
                // width: '50%',
                flexDirection: 'column',
                flexGrow: (_params === null || _params === void 0 ? void 0 : _params.contentPosition) == 'CENTER' ? 1 : 0,
            },
        },
        '& button': { width: '100%' },
    },
    link: {
        width: '100%',
        display: 'flex',
        marginBottom: spacing.small,
        '&:last-child': {
            marginBottom: 0,
        },
        '& button': {
            flexGrow: 1,
            whiteSpace: 'pre-wrap',
        },
        [theme.breakpoints.up('md')]: {
            width: _params.linksCount === 1 ? '100%' : '50%',
            '& button': { width: '100%' },
            '&:not(:last-child)': {
                marginBottom: '0px',
                marginRight: spacing.small,
            },
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
        },
    },
    headingLine: {
        whiteSpace: 'nowrap',
        alignSelf: 'flex-start',
        padding: spacing.xs,
        '&:first-child': {
            transform: 'rotate(358deg) translate(5px,5px)',
            transformOrigin: 'bottom left',
        },
    },
    COBALT: {
        backgroundColor: colors.cobalt[500],
    },
    JADE: {
        backgroundColor: colors.jade[500],
    },
    WILD_EXOTIC: {
        backgroundColor: colors.wildExotic[500],
    },
    centerHeading: {
        alignSelf: 'center',
    },
    ffCenterContentInner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& a': {
            width: _params.linksCount === 1 ? '100%' : '50%',
        },
    },
    image: {
        '& img': {
            objectFit: 'contain',
        },
    },
}));
export default useStyles;
